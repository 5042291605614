import Cookies from "js-cookie";
import { Quote } from "../types/Quote.interface";
import { SegmentUserIdentifier, BrowserExtraData, BuilderUserIdentifier, Partner247 } from "spot-types/misc/BrowserExtraData";
import { IdentifyTraitsTransforms } from "spot-types/vendor/enhanced-commerce/IdentifyTraitsTransforms";
import { QuoteDataUtils } from "./QuoteDataUtils";
import { StorageUtils } from "./StorageUtils";

type SegmentEcommerceEvents =
    | "Product Clicked"
    | "Product Viewed"
    | "Product Added"
    | "Product Removed"
    | "Cart Viewed"
    | "Checkout Started"
    | "Checkout Step Viewed"
    | "Checkout Step Completed"
    | "Payment Info Entered"
    | "Order Completed"
    | "Order Updated"
    | "Order Refunded"
    | "Order Cancelled";

type SegmentOtherEvents = "Continue Clicked";

type SegmentEvents = SegmentEcommerceEvents | SegmentOtherEvents;

type EventProperties = {
    [x: string]: any;
};

type SpotSession = {
    browserSessionId?: string;
    testUserId?: string;
};

export class AnalyticsUtils {
    getSegmentIds(email?: string): SegmentUserIdentifier | undefined {
        if (typeof window === `undefined`) {
            return undefined;
        }

        const anonymousId = Cookies.get("ajs_anonymous_id");
        const userId = Cookies.get("ajs_user_id");

        let finalUserId = userId;

        // If userId is present and decoding is required, ensure you continue doing so safely
        if (userId && !!email) {
            try {
                const decodedUserId = window.atob(userId);
                if (decodedUserId !== email) {
                    finalUserId = window.btoa(email); // Re-encode the email if comparison fails
                    window.analytics?.alias(finalUserId, userId);
                }
            } catch (error) {
                console.error(`Error decoding userId: ${error}`);
                finalUserId = window.btoa(email); // Fallback to re-encoding the email on error
            }
        } else if (!!email) {
            finalUserId = window.btoa(email); // Encode email if userId isn't present
        }

        return {
            userId: finalUserId ?? "",
            anonymousId: anonymousId ?? "" // Ensure anonymousId is always a string, even though it's checked for presence above
        };
    }

    identifyUser(userId: string, quote: Quote) {
        const identifyPayload = IdentifyTraitsTransforms.petQuoteToIdentifyTraits(QuoteDataUtils.quoteToPetQuote(quote));
        window.analytics?.identify(userId, identifyPayload);
        if (!!window.DD_RUM) {
            window.DD_RUM.onReady(() => {
                window.DD_RUM?.setUser({ id: userId, ...identifyPayload });
            });
        }
    }

    trackSegmentEvent(event: SegmentEvents, properties?: EventProperties) {
        try {
            window.analytics?.track(event, properties);
        } catch (error) {
            console.error(`Error tracking Segment event: `, event);
        }

        if (window.DD_RUM) {
            try {
                window.DD_RUM.onReady(() => {
                    window.DD_RUM?.addAction(event, properties);
                });
            } catch (error) {
                console.error(`Error tracking Datadog event: `, event);
            }
        }
    }

    buildSegmentLocationObject() {
        if (typeof window === "undefined" || typeof document === "undefined") return {};

        const { location }: { location: Location } = window;

        // Document props
        const title = document?.title || `Start Your Free Quote | Spot Pet Insurance`;
        const referrer = document?.referrer || undefined;

        // Location props
        const { pathname = ``, search = ``, href = `` } = location || {};

        const locationObj = {
            path: pathname,
            referrer,
            search,
            title,
            url: href
        };

        return locationObj;
    }

    initSegmentPageCall(attempts = 0) {
        if (!!window.analytics?.page) {
            window.analytics?.page(undefined, undefined, this.buildSegmentLocationObject());
            return;
        }

        if (attempts > 0) {
            setTimeout(() => this.initSegmentPageCall(attempts - 1), 500);
        }

        if (attempts === 0) {
            console.warn(`Unable to locate analytics.js`);
        }
    }

    // VWO

    getVwoIds(): BrowserExtraData {
        const vwoId = Cookies.get("_vwo_uuid") ?? ``;
        const vwoIdV2 = Cookies.get("_vwo_uuid_v2") ?? ``;
        return {
            vwoUUID: vwoId,
            vwoUUIDv2: vwoIdV2
        };
    }

    trackVwoEvent = (eventName: string, properties?: Record<string, any>, preventiveCare?: Record<string, any>) => {
        const VWO = window.VWO;
        if (!VWO) return;

        // Pass VWO.event if it exists, else VWO isn't loaded, log event args.
        VWO.event =
            VWO.event ||
            function (...args: any[]) {
                console.warn(`VWO event not loaded: ${args}`);
            };
        VWO.event(eventName, properties, preventiveCare);
    };

    updateVwoAttribute = (name: string, value: string | number | boolean) => {
        const VWO = window.VWO;
        if (!VWO) return;
        VWO.visitor =
            VWO.visitor ||
            function (...args: any[]) {
                VWO.push(["visitor"].concat(args));
            };

        VWO.visitor({ [name]: value });
    };

    getVwoUserData = () => {
        const vwoIds = this.getVwoIds();
        const _vwo_exp = window?._vwo_exp;

        let vwoCampaignID: string | undefined;
        let vwoGroupID: string | undefined;

        // Logic to iterate over experiments and find the relevant campaign information
        if (!!_vwo_exp && typeof _vwo_exp === "object") {
            Object.values(_vwo_exp).find(exp => {
                if (exp.combination_chosen) {
                    vwoCampaignID = exp.id.toString();
                    vwoGroupID = exp.combination_chosen;
                }
            });
        }

        const vwoData: BrowserExtraData = {
            ...vwoIds,
            vwoCampaignID,
            vwoGroupID
        };

        return vwoData;
    };

    getQueryParams = (quoteParams?: Record<string, any>) => {
        const currentParams = typeof window !== "undefined" ? Object.fromEntries(new URLSearchParams(window.location.search)) : {};
        // const quoteParams = quote?.extra?.queryParams || {};
        const mergedParams = { ...quoteParams, ...currentParams };

        return mergedParams;
    };

    getBuilderSessionId(): string {
        const currentSessionId = Cookies.get("builderSessionId") ?? "";
        return currentSessionId;
    }

    getBuilderTests(): BuilderUserIdentifier["tests"] {
        const allCookies = Cookies.get();
        const filteredCookies = Object.entries(allCookies).filter(([name, value]) => name.includes("builder.tests."));

        const tests: { [testId: string]: string } = {};
        filteredCookies.forEach(([name, value]) => {
            const testId = name.replace("builder.tests.", "");
            tests[testId] = value;
        });

        return tests;
    }

    getBuilderObject(): BuilderUserIdentifier {
        return {
            sessionId: this.getBuilderSessionId(),
            tests: this.getBuilderTests()
        };
    }

    getPartner247Object(): Partner247 {
        const queryParams = this.getQueryParams();

        return {
            visitor_id: Cookies.get("sn.vi") ?? "",
            ad_request_id: Cookies.get("tfs-pzn-adreqid") ?? queryParams["adreqid"] ?? ""
        };
    }

    getSpotSessionObject(): SpotSession {
        const browserSessionId = StorageUtils.getItem("browserSessionId") ?? undefined;
        const testUserId = Cookies.get("tuid");
        return {
            browserSessionId,
            testUserId
        };
    }

    constructExtra(quoteParams?: Record<string, string>, email?: string): BrowserExtraData & SpotSession {
        return {
            queryParams: this.getQueryParams(quoteParams),
            segment: this.getSegmentIds(email),
            ...this.getVwoUserData(),
            builder: {
                ...this.getBuilderObject()
            },
            partner247: {
                ...this.getPartner247Object()
            },
            ...this.getSpotSessionObject()
        };
    }
}
